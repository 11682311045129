import AddCards from "./Card";
import "./stylesheet.css";
function App() {
  return (
    <div>
      <header className="card">
        <h1 className="c">Links to My Three ADSB Recievers</h1>
        <p>
          The first three links below open the output of each of my three
          receivers in my house.
          <hr />
          The last two links will take you to the worldwide maps of the
          aggegators ADSBExchange and Flightaware. <br />
          <div style={{ color: "red", textAlign: "center" }}>
            Click on the arrows on the left for more info
          </div>{" "}
          <hr />
          (This program is a practice app to learn the React library.)
        </p>
      </header>
      <AddCards />
    </div>
  );
}

export default App;
