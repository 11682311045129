// list order summary, url, detail
const data = [
  [
    `ADSBExchange - East Side (click arrow for detail)`,
    "http://45.3.237.26:3333/tar1090/",
    `Link to the output of the ADSBExchange receiver on the east side of
      the house. Consists of Raspberry Pi and SDR receiver. Best site to use
      for military and government aircraft. Also has best range due to antenna.`,
  ],
  [
    "FlightAware - East Side",
    "http://45.3.237.26:3338/skyaware/",
    `Link to the output of the ADSBExchange receiver on the east side of the
      house. Consists of Raspberry Pi and SDR receiver. Flightaware has the best
      detail on commercial flights and will display things as altitude and speed
      graphs for the entire route.`,
  ],
  [
    "FlightAware - West Side",
    "http://45.3.237.26:3334/skyaware/",
    `Link to the output of the ADSBExchange receiver on the east side of the
      house. Consists of Raspberry Pi and SDR receiver.`,
  ],
  [
    "ADSBExchange Worldwide Map",
    "https://globe.adsbexchange.com/",
    `Popular aggregator and probably the best at showng military and government
      aircraft.`,
  ],
  [
    "FlightAware Worldwide Map",
    "https://www.flightaware.com/live/",
    `FlightAware is a very popular aggregator with lots of detail on commercial
      flights. Airline info such as Altitudes and Airspeed graphed for the entire
      route, etc`,
  ],
];
export default data;
